<template>
  <v-card>
    <v-card-title class="primary white--text headline">
      Lista de usuarios
    </v-card-title>
    <v-row justify="space-between">
      <v-col cols="3">
        <v-text-field
          class="mx-2 mt-2"
          clearable
          outlined
          dense
          append-icon="mdi-magnify"
          @input="filterList($event)"
          :value="searchBar"
          label="Buscar..."
        ></v-text-field>
        <v-virtual-scroll :items="ListOfUsers" :item-height="120" height="700">
          <template v-slot:default="{ item }">
            <div>
              <v-list-item
                class="my-5"
                active-class="white--text"
                :selectable="true"
                @click="setSelectedUser(item)"
              >
                <v-list-item-avatar>
                  <v-avatar color="primary" size="31">
                    <span class="white--text headline">{{
                      item.user_account.substr(0, 1).toUpperCase()
                    }}</span>
                  </v-avatar>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title
                    v-html="item.user_account"
                  ></v-list-item-title>
                  <v-list-item-subtitle
                    v-html="item.user_name"
                  ></v-list-item-subtitle>
                </v-list-item-content>
                <span v-if="item.user_online">{{ item.battery }}%</span>
                <v-icon v-if="item.user_online" :color="batteryColor(item)">{{
                  battery(item)
                }}</v-icon>
                <v-icon :color="checkStatus(item.user_online)"
                  >mdi-power</v-icon
                >
                <v-icon :color="checkStatus(item.user_status)"
                  >mdi-checkbox-blank-circle</v-icon
                >
              </v-list-item>
              <v-divider></v-divider>
            </div>
          </template>
        </v-virtual-scroll>
      </v-col>

      <v-divider vertical></v-divider>

      <v-col cols="9" justify="center" align="center">
        <v-scroll-y-transition mode="out-in">
          <div
            v-if="!selected"
            class="title grey--text text--lighten-1 font-weight-light"
            style="align-self: center"
          >
            Select a User
          </div>
          <v-card v-else :key="selected.bill_id" class="pt-6 mx-auto" flat>
            <v-card-text>
              <v-avatar color="primary" size="93">
                <span class="white--text text-h2">{{
                  selected.user_account.substr(0, 1).toUpperCase()
                }}</span>
              </v-avatar>

              <h3 class="headline mb-2">
                {{ selected.user_account }}
              </h3>
              <div class="blue--text mb-2">
                {{ selected.user_name }}
              </div>
            </v-card-text>
            <v-divider></v-divider>
            <div class="blue--text mb-2">
              Fecha de termino de la licencia: {{ selected.bill_end_time }}
            </div>
            <div class="blue--text mb-2">
              <p>Privilegios:</p>
              <v-chip-group>
                <v-chip
                  color="primary"
                  class="mx-auto white--text"
                  v-for="(tag, index) in privilegesOfUser"
                  :key="index"
                >
                  {{ tag.text }}
                </v-chip>
              </v-chip-group>
            </div>
            <div class="blue--text mb-2">
              <p>Grupos:</p>
              <v-chip-group>
                <v-chip
                  color="primary"
                  class="mx-auto white--text"
                  v-for="(tag, index) in selected.group_names"
                  :key="index"
                >
                  {{ tag }}
                </v-chip>
              </v-chip-group>
            </div>
            <div class="blue--text mb-2">
              Numero de licencia: {{ selected.bill_id }}
            </div>
          </v-card>
        </v-scroll-y-transition>
      </v-col>
    </v-row>
    <FAB
      v-if="selected && selected.user_status == 1"
      :buttons="[
        {
          fn: openDialogPauseUser,
          icon: 'mdi-pause-circle-outline',
          color: 'warning',
          name: 'Pausar usuario',
        },
        {
          fn: openDialogDeleteUser,
          icon: 'mdi-delete-outline',
          color: 'red',
          name: 'Eliminar usuario',
        },
        {
          fn: openDialogEditUser,
          icon: 'mdi-pencil-outline',
          color: 'green',
          name: 'Editar usuario',
        },
      ]"
    />
    <FAB
      v-else-if="selected"
      :buttons="[
        {
          fn: openDialogActiveUser,
          icon: 'mdi-play-circle-outline',
          color: 'warning',
          name: 'Activar usuario',
        },
        {
          fn: openDialogDeleteUser,
          icon: 'mdi-delete-outline',
          color: 'red',
          name: 'Eliminar usuario',
        },
        {
          fn: openDialogEditUser,
          icon: 'mdi-pencil-outline',
          color: 'green',
          name: 'Editar usuario',
        },
      ]"
    />
    <DialogEditUser />
    <DialogAddUser />
    <DialogPauseUser />
    <DialogActiveUser />
    <DialogDeleteUser />
  </v-card>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import DialogEditUser from "./Dialogs/Users/EditUser";
import DialogAddUser from "./Dialogs/Users/AddUser";
import DialogPauseUser from "./Dialogs/Users/PuseUser";
import DialogActiveUser from "./Dialogs/Users/ActiveUser";
import DialogDeleteUser from "./Dialogs/Users/DeleteUser";
import FAB from "./StandardizedComponents/FAB";
export default {
  components: {
    FAB,
    DialogEditUser,
    DialogAddUser,
    DialogPauseUser,
    DialogActiveUser,
    DialogDeleteUser,
  },
  created() {
    this.$store.dispatch("Users/getUsers");
  },
  computed: {
    ...mapState("Users", [
      "ListOfUsers",
      "selected",
      "privilegesOfUser",
      "searchBar"
    ]),
  },
  methods: {
    checkStatus(value) {
      if (value == "1") return "green";
      else return "red";
    },
    battery(item) {
      return item.battery < 30
        ? "mdi-battery-low"
        : item.battery < 60
        ? "mdi-battery-medium"
        : "mdi-battery-high";
    },
    batteryColor(item) {
      return item.battery < 30 ? "red" : item.battery < 60 ? "orange" : "green";
    },
    ...mapActions("Users", [
      "setSelectedUser",
      "openDialogEditUser",
      "filterList"
    ]),
    ...mapMutations("Users", [
      "openDialogPauseUser",
      "openDialogActiveUser",
      "openDialogDeleteUser",
    ]),
  },
};
</script>

<style>
</style>