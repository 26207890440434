<template>
<v-tooltip right>
    <template v-slot:activator="{ on, attrs }">
    <v-btn
        class="mt-2"
        fab
        dark
        small
        color="green"
        v-bind="attrs"
          v-on="on"
        @click="Fn"
    >
        <v-icon dark>
            mdi-plus
        </v-icon>
    </v-btn>
    </template>
        <span>{{Name}}</span>
</v-tooltip>
</template>
<script>
export default {
    props:{
        Fn: Function, //Is used to open thee dialog
        Name: String
    }
}
</script>