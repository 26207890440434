<template>
  <v-dialog :value="dialogAddUser" :persistent="true" max-width="1200px" :scrollable='true'>
        <v-card outlined>
            <v-card-title>
                <span class="headline">Agregar Usuario</span>
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-row dense>
                        <v-col
                        cols="12"
                        sm="6"
                        md="4"
                        >
                            <TextFields Type="text" :Fn="updateUserAccount" :Rules="[rules.onlyLettersAndNumbers, rules.maxLengthAccount]" :Value="selected.user_account" Label="Cuenta de usuario"/>
                        </v-col>
                        
                        <v-col
                        cols="12"
                        sm="6"
                        md="4"
                        >
                            <TextFields :Fn="updateUsername" :Rules="[rules.onlyLettersAndNumbers, rules.maxLengthAccount]" Type="text" :Value="selected.user_name" Label="Nombre de usuario"/>
                        </v-col>
                        <v-col
                        cols="1"
                        sm="1"
                        md="3"
                        >
                            <Autocomplete :Fn="updateDropdownBill" :GetValues="getDropdownBills" :Value="dropdownBill" :Items="dropdownBills" Label="Numero de licencia"/>
                        </v-col>
                        <v-col cols="1">
                            <ButtonToAdd Name="Activar licencia" :Fn="openDialogActivateBill"/>
                        </v-col>
                        <v-col
                        cols="12"
                        sm="6"
                        md="6"
                        >
                            <PasswordField :Fn="updatePassword" :Rules="[rules.maxLengthPassword, rules.minLengthPassword]" :Value="password" Label="Contraseña"/>
                        </v-col>
                        <v-col
                        cols="1"
                        sm="1"
                        md="6"
                        >
                            <TextFields Label="Telefono" :Fn="updatePhoneNumber" Type="number" :Value="selected.tel_number"/>
                        </v-col>
                        <v-col
                        cols="12"
                        sm="6"
                        md="6"
                        >
                            <MultiAutocomplete Label="Grupos" :GetValues="getDropdownGroups" :Items="dropdownGroups" :Fn="updateDropdownGroup" :Value="dropdownGroup"/>
                        </v-col>
                        <v-col
                        cols="12"
                        sm="6"
                        md="6"
                        >
                            <Autocomplete Label="Grupo predefinido" :GetValues="getDropdownGroups" :Items="dropdownGroups" :Fn="updateDropdownDefaultGroup" :Value="dropdownDefaultGroup"/>
                        </v-col>
                        
                        <v-col
                        cols="12"
                        sm="6"
                        md="4"
                        >
                            <v-slider
                            class="mt-3"
                            max="10"
                            min="1"
                            
                            :value="selected.priority+1"
                            label="Prioridad"
                            thumb-label="always"
                            ></v-slider>
                        </v-col>
                        <v-col
                        cols="12"
                        sm="6"
                        md="4"
                        >
                            <TextFields Label="IMEI" :Fn="updateIMEI" Type="text" :Value="selected.imei"/>
                        </v-col> 
                        <v-col
                        cols="12"
                        sm="6"
                        md="4"
                        >
                            <TextFields Label="Descripcion" :Fn="updateDescription" Type="text" :Value="selected.desc"/>
                        </v-col>   
                        <v-col
                        cols="12"
                        sm="6"
                        md="12"
                        > 
                            <MultiAutocomplete Label="Privilegios" :Fn="setPrivilegesOfUser" :Value="privilegesOfUser" :Items="privilegeList" :Chips="true"/>
                        </v-col>
                    </v-row>
                </v-container>
                
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="red"
                    text
                    @click="closeDialogAddUser()"
                >
                Close
                </v-btn>
                <v-btn
                    color="green"
                    text
                    @click="addUser()"
                >
                    Save
                </v-btn>
            </v-card-actions>
        </v-card>
        <DialogActivateBill :CloseFn='closeDialogActivateBill'/>
    </v-dialog>
    
</template>

<script>
import TextFields from '../../StandardizedComponents/textFields'
import Autocomplete from '../../StandardizedComponents/autocompleteFields'
import MultiAutocomplete from '../../StandardizedComponents/autocompleteMultiselect.vue'
import PasswordField from '../../StandardizedComponents/passwordFields'
import ButtonToAdd from '../../StandardizedComponents/buttonToAdd'
import DialogActivateBill from '../../Dialogs/Bills/ActivateBill'
import {mapState,mapMutations, mapActions} from 'vuex'
export default {
    data(){
        return {
            rules: {
                onlyLettersAndNumbers: value => {
                    var alphaExp = /^[a-zA-Z0-9]+$/
                    return alphaExp.test(value) || 'Solo se admiten números y letras'
                },
                maxLengthAccount: value => value.length <= 15 || 'Max 15 characters',
                maxLengthPassword: value => value.length <= 12 || 'Max 12 characters',
                minLengthPassword: value => value.length >= 6 || 'Min 6 characters',
            }
        }
    },
    computed:{
        ...mapState('Users',[
            'dialogAddUser',
            'selected',
            'password',
            'privilegesOfUser',
            'privilegeList']),
        ...mapState('Billing',['dropdownBills','dropdownBill']),
        ...mapState('Groups',['dropdownGroups','dropdownGroup','dropdownDefaultGroup'])
    },

    methods:{
        ...mapActions('Users',['closeDialogAddUser','addUser','updatePassword']),
        ...mapActions('Billing',['getDropdownBills','openDialogActivateBill','closeDialogActivateBill']),
        ...mapActions('Groups',['getDropdownGroups']),
        ...mapMutations('Users',[
            'setPrivilegesOfUser',
            'updateBill',
            'updateUserAccount',
            'updateUsername',
            'updatePhoneNumber',
            'updateIMEI',
            'updateDescription',
            'updatePriority'
        ]),
        ...mapMutations('Billing',['updateDropdownBill']),
        ...mapMutations('Groups',['updateDropdownGroup','updateDropdownDefaultGroup'])

    },
    components:{
        TextFields,
        Autocomplete,
        MultiAutocomplete,
        PasswordField,
        ButtonToAdd,
        DialogActivateBill
    }

}
</script>

<style>

</style>