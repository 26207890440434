<template>
  <div>
    <Navbar/>
    <v-btn
      class="ma-2"
      outlined
      color="success"
      @click="openDialogAddUser"
    >
      Agregar cuenta
    </v-btn>
    <TableUsers/>
  </div>
</template>

<script> 
  import Navbar from '../components/Navbar'
  import TableUsers from '../components/TableUsers'
  import {mapActions} from 'vuex'
  export default {
    name: 'Home',
    methods:{
      ...mapActions('Users',['openDialogAddUser'])
    },
    components: {
      Navbar,
      TableUsers
    }
  }
</script>
