<template>
  <v-dialog :value="dialogDeleteUser" :persistent="true" max-width="600px" :scrollable='true'>
        <v-card outlined>
            <v-card-title>
                <span class="headline">Eliminar Usuario</span>
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-row dense>
                        
                        <v-col
                        cols="12"
                        >
                            <v-alert
                            prominent
                            type="error"
                            >
                            Esta a punto de eliminar el usuario 
                            <span class="yellow--text">{{selected.user_name}}</span>.
                            Si elimina el usuario, perdera su licencia. 
                            Y para tener otra tendra que pagarla
                            </v-alert>
                        </v-col> 
                        <v-col
                        cols="12"
                        sm="6"
                        md="4"
                        >
                           
                        </v-col>   
                        <v-col
                        cols="12"
                        sm="6"
                        md="12"
                        > 
                            
                        </v-col>
                    </v-row>
                </v-container>
                
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="primary"
                    text
                    @click="closeDialogDeleteUser()"
                >
                Close
                </v-btn>
                <DeleteProgressButton :Fn="deleteUser"/>
            </v-card-actions>
        </v-card>
        
    </v-dialog>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import DeleteProgressButton from '../../StandardizedComponents/deleteProgressButton'
export default {
    components:{
        DeleteProgressButton
    },
    computed:{
        ...mapState('Users',['dialogDeleteUser','selected'])
    },
    methods:{
        ...mapMutations('Users',['closeDialogDeleteUser']),
        ...mapActions('Users',['deleteUser'])
    }
}
</script>

<style>

</style>