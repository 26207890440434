<template>
  <v-dialog :value="dialogActiveUser" :persistent="true" max-width="600" :scrollable='true'>
        <v-card outlined>
            <v-card-title>
                <span class="headline">Pausar usuario</span>
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-subheader>
                        Informacion del usuario
                    </v-subheader>
                    <v-row dense>
                        <v-col cols="10" class="mx-auto" v-if="selected">
                            <p class="black--text text-h4 mx-auto">{{selected.user_name.toUpperCase()}}</p>
                            <p class="black--text text-h4">{{selected.user_account.toUpperCase()}}</p>
                        </v-col>
                    </v-row>
                </v-container>
                
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="red"
                    text
                    @click="closeDialogActiveUser()"
                >
                Close
                </v-btn>
                <v-btn
                    color="success"
                    text
                    @click="activeUser()"
                >
                Activar
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapState,mapActions, mapMutations } from 'vuex'
export default {

    computed:{
        ...mapState('Users',['dialogActiveUser','selected'])
    },
    methods:{
        ...mapActions('Users',['activeUser']),
        ...mapMutations('Users',['closeDialogActiveUser'])
    }
}
</script>

<style>

</style>