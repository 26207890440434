<template>
  <v-dialog :value="dialogEditUser" :persistent="true" max-width="1200px" :scrollable='true'>
        <v-card outlined>
            <v-card-title>
                <span class="headline">Editar usuario</span>
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-subheader>
                        Informacion del usuario
                    </v-subheader>
                    <v-row dense>
                        <v-col
                        cols="12"
                        sm="6"
                        md="4"
                        >
                            <TextFields :Disabled="true" Type="text" :Value="selected.user_account" Label="Cuenta de usuario"/>
                        </v-col>
                        
                        <v-col
                        cols="12"
                        sm="6"
                        md="4"
                        >
                            <TextFields :Fn="updateUsername" Type="text" :Value="selected.user_name" Label="Nombre de usuario"/>
                        </v-col>
                        <v-col
                        cols="1"
                        sm="1"
                        md="4"
                        >
                            <TextFields :Disabled="true" Type="text" :Value="selected.bill_id" Label="Numero de licencia"/>
                        </v-col>

                        <v-col
                        cols="12"
                        sm="6"
                        md="6"
                        >
                            <PasswordField :Fn="updatePassword" :Value="password" Label="Contraseña"/>
                        </v-col>
                        <v-col
                        cols="1"
                        sm="1"
                        md="6"
                        >
                            <TextFields Label="Telefono" :Rules="[]" :Fn="updatePhoneNumber" Type="number" :Value="selected.tel_number"/>
                        </v-col>
                        <v-col
                        cols="12"
                        sm="6"
                        md="4"
                        >
                            <MultiAutocomplete Label="Grupos" :GetValues="getDropdownGroups" :Items="dropdownGroups" :Fn="updateDropdownGroup" :Value="dropdownGroup"/>
                        </v-col>
                        <v-col
                        cols="12"
                        sm="6"
                        md="4"
                        >
                            <Autocomplete Label="Grupo predefinido" :GetValues="getDropdownGroups" :Items="dropdownGroups" :Fn="updateDropdownDefaultGroup" :Value="dropdownDefaultGroup"/>
                        </v-col>
                        <v-col
                        cols="12"
                        sm="4"
                        md="4">
                            <v-select
                                v-if="selectedUserType"
                                :items="userTypes"
                                :value="selectedUserType"
                                return-object
                                clearable
                                outlined
                                @change="updateSelectedUserType($event)"
                                label="Tipo de usuario"
                                :prepend-icon="`${selectedUserType.icon}`"
                            ></v-select>
                            <v-select
                                v-else
                                :items="userTypes"
                                :value="selectedUserType"
                                return-object
                                clearable
                                outlined
                                @change="updateSelectedUserType($event)"
                                label="Tipo de usuario"
                            ></v-select>
                        </v-col>
                        <v-col
                        cols="12"
                        sm="6"
                        md="4"
                        >
                            <v-slider
                            class="mt-3"
                            max="10"
                            min="1"
                            :value="selected.priority"
                            label="Prioridad"
                            @change="updatePriority"
                            thumb-label="always"
                            ></v-slider>
                        </v-col>
                        <v-col
                        cols="12"
                        sm="6"
                        md="4"
                        >
                            <TextFields Label="IMEI"  :Fn="updateIMEI" Type="text" :Value="selected.imei"/>
                        </v-col> 
                        <v-col
                        cols="12"
                        sm="6"
                        md="4"
                        >
                            <TextFields Label="Descripcion" :Fn="updateDescription" Type="text" :Value="selected.desc"/>
                        </v-col>   
                        <v-col
                        cols="12"
                        sm="6"
                        md="12"
                        > 
                            <MultiAutocomplete Label="Privilegios" :Fn="setPrivilegesOfUser" :Value="privilegesOfUser" :Items="privilegeList" :Chips="true"/>
                        </v-col>
                        
                    </v-row>
                </v-container>
                
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="red"
                    text
                    @click="closeDialogEditUser()"
                >
                Close
                </v-btn>
                <v-btn
                    color="green"
                    text
                    @click="updateUser()"
                >
                    Save
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import {mapMutations, mapState,mapActions} from 'vuex'
import TextFields from '../../StandardizedComponents/textFields'
import Autocomplete from '../../StandardizedComponents/autocompleteFields'
import MultiAutocomplete from '../../StandardizedComponents/autocompleteMultiselect.vue'
import PasswordField from '../../StandardizedComponents/passwordFields'
export default {
    computed:{
        ...mapState('Users',['dialogEditUser','selected','privilegesOfUser','privilegeList','password','userTypes','selectedUserType']),
        ...mapState('Billing',['dropdownBills','dropdownBill']),
        ...mapState('Groups',['dropdownGroups','dropdownGroup','dropdownDefaultGroup'])
    },
    methods:{
        ...mapMutations('Users',[
            'updateBill',
            'updateUserAccount',
            'updateUsername',
            'updatePhoneNumber',
            'updateIMEI',
            'updateDescription',
            'updatePriority',
            'setPrivilegesOfUser',
            'updateSelectedUserType'
        ]),
        ...mapActions('Users',['updatePassword','updateUser','closeDialogEditUser']),
        ...mapActions('Billing',['getDropdownBills']),
        ...mapActions('Groups',['getDropdownGroups']),
        ...mapMutations('Billing',['updateDropdownBill']),
        ...mapMutations('Groups',['updateDropdownGroup','updateDropdownDefaultGroup'])
    },
    components:{
        TextFields,
        Autocomplete,
        MultiAutocomplete,
        PasswordField
    }
}
</script>

<style>

</style>